import React, { useEffect } from "react";
import { post} from "../Services/http";

const CadVisualizer = () => {
    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.data.type === 'UpdatePartConfiguration') {
                post("/api/cart/addDynamakerCartItemModelV2", event.data)
                    .then(res => res.json())
                    .then(res => window.location.href = res.checkoutUrl)
            }
            if (event.data.type === 'litium.returnConfigurationData') {
                var app = document.getElementById("js-cad-iframe");
                var designation = app.dataset.designation;
                post("/api/cart/addDynamakerCartItemModelV3?designation=" + designation, event.data)
                    .then(res => res.json())
                    .then(res => window.location.href = res.checkoutUrl)
            }
        })
    })
    
    return(
        <div>
        </div>
    )
}


export default CadVisualizer;